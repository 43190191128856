import { defaultState } from './state';
import { makeId } from './helpers';
import { v4 as uuid } from 'uuid';
import Vue from 'vue';

const tabsFinder = (tab) =>
	({
		Tab: (error, state) =>
			state.tabs
				.filter(
					(tab) => tab.type === error.tab && (error.tabId === undefined || error.tabId.toString() === tab.id.toString())
				)
				.map((tab) => tab.id),
		AttachmentField: (error, state) =>
			Object.entries(state.attachments)
				.map((e) => e[1])
				.reduce((acc, val) => acc.concat(val), [])
				.filter((attachment) => `${attachment.fileId}` === error.fileId)
				.map((attachment) => attachment.tabId),
		ContributorField: (error, state) =>
			Object.entries(state.contributors)
				.map((e) => ({ tabId: e[0], ids: e[1].map((c) => `${c.id}`) }))
				.filter((c) => c.ids.includes(error.resourceId))
				.map((c) => parseInt(c.tabId)),
		EntryField: (error, state) =>
			state.fields.filter((field) => field.slug === error.fieldSlug).map((field) => field.tabId),
		RefereeField: (error, state) =>
			Object.entries(state.referees)
				.map((e) => ({ tabId: e[0], ids: e[1].map((c) => `${c.id}`) }))
				.filter((c) => c.ids.includes(error.resourceId))
				.map((c) => parseInt(c.tabId)),
	}[tab] || (() => []));

export default {
	/**
	 * Store entry form state
	 */
	storeEntryFormState(state, payload) {
		const property = Object.keys(payload)[0];
		const value = Object.values(payload)[0];

		if (Object.getOwnPropertyDescriptor(state, property)) {
			Vue.set(state, property, value);
		}
	},

	setReadOnly(state, readOnly) {
		Vue.set(state, 'locks', { ...state.locks, readOnly });
	},

	/**
	 * Store the entry.
	 */
	storeEntry(state, entry) {
		Vue.set(state, 'entry', entry);
		Vue.set(state, 'title', entry.title);
	},

	createFormId(state) {
		const id = Math.random().toString(16).substr(2, 15);
		Vue.set(state, 'formId', id);
	},

	createFormSessionUuid(state) {
		Vue.set(state, 'formSessionUuid', uuid());
	},

	storeCategory(state, category) {
		const locked = state.locks.lockedCategory || state.locks.readOnly;

		if (!state.chapterId || !category.chapters.includes(state.chapterId) || (category.deletedAt && !locked)) {
			return;
		}

		if (locked || category.active || state.hasManagerRole) {
			Vue.set(state, 'selectedCategoryId', category.id);
		}
	},

	reorderFields(state, fields) {
		Vue.set(
			state,
			'fields',
			state.fields
				.map((field) => {
					if (fields[field.id]) {
						return { ...field, order: fields[field.id] };
					}

					return field;
				})
				.sort((a, b) => parseFloat(a.order) - parseFloat(b.order))
		);
	},

	reorderTabs(state, tabs) {
		Vue.set(
			state,
			'tabs',
			state.tabs
				.map((tab) => {
					if (tabs[tab.id]) {
						return { ...tab, order: tabs[tab.id] };
					}

					return tab;
				})
				.sort((a, b) => parseFloat(a.order) - parseFloat(b.order))
		);
	},

	setFormHash(state, hash) {
		Vue.set(state, 'formHash', hash);
	},

	storeUser(state, user) {
		Vue.set(state, 'user', user);
	},

	selectCategory(state, selectedCategoryId) {
		Vue.set(state, 'selectedCategoryId', selectedCategoryId);
	},

	/**
	 * Once the tabs are available they have to be merged with the state object.
	 */
	storeTabs(state, tabs) {
		Vue.set(state, 'tabs', tabs);

		/**
		 * reset tabId if theres no tab passed in url or that tab is disabled or unknown
		 */
		if (!state.tabId || !tabs.find((t) => t.id === state.tabId)) {
			Vue.set(state, 'tabId', tabs.length ? tabs[0].id : null);
		}
	},

	storeFiles(state, { file, id, fieldId }) {
		const field = fieldId ? state.fields.find((field) => field.id === fieldId) : null;

		if (file) {
			Vue.set(state.tempFiles, id, file);
		} else {
			Vue.delete(state.tempFiles, id);
		}

		if (field) {
			Vue.set(state, 'fields', [
				...state.fields.map((field) => (field.id !== fieldId ? field : { ...field, file: file })),
			]);
		}
	},

	deleteFileFromEntry(state, { fieldId }) {
		const field = state.fields.find((field) => field.id === fieldId);

		if (field) {
			Vue.set(state, 'values', {
				...state.values,
				[field.resource]: { ...state.values[field.resource], [field.slug]: '' },
			});
		}
	},

	/**
	 * Select a tab.
	 */
	selectTab(state, tabId) {
		Vue.set(state, 'tabId', tabId);
	},

	/**
	 * Select a tab by slug.
	 */
	selectTabBySlug(state, slug) {
		const tab = state.tabs.find((t) => t.slug === slug);

		if (tab) {
			Vue.set(state, 'tabId', tab.id);
		}
	},

	setTimestamp(state, updatedAt) {
		Vue.set(state, 'updatedAt', updatedAt);
	},

	/**
	 * Once the fields are available they have to be merged with the state object.
	 */
	storeFields(state, fields) {
		const ids = fields.map((o) => o.id);
		const uniqueFields = fields.filter(({ id }, index) => !ids.includes(id, index + 1)).sort((a, b) => a.order - b.order);

		Vue.set(state, 'fields', uniqueFields);
		this.dispatch('entryForm/setFieldTitlesForFormula');

		// At this point the values can be extracted - the form elements will be bound to these values.
		const values = {};
		uniqueFields.forEach((field) => (values[field.slug] = field.value));
		// Do not overwrite changed state values
		Vue.set(state.values, 'Entries', { ...values, ...state.values.Entries });
	},

	/**
	 * Merge uploader options with corresponding tabs.
	 */
	storeTabUploaderOptions(state, uploaderOptions) {
		state.tabs.forEach((tab, index) => {
			Vue.set(state.tabs[index], 'uploaderOptions', uploaderOptions[tab.id]);
		});
	},

	storeTabAttachments(state, { attachments, tabId }) {
		Vue.set(state.attachments, tabId, attachments || []);
	},

	setTabLinks(state, { links, tabId }) {
		Vue.set(state.links, tabId, links || []);
	},

	/**
	 * Merge attachments with the state object.
	 */
	mergeAttachments(state, { attachments, attachmentFields }) {
		Object.keys(attachments).forEach((tabId) => {
			// eslint-disable-next-line eqeqeq
			const fields = attachmentFields.filter((field) => field.tabId == tabId);

			attachments[tabId].forEach((attachment) => {
				attachment['fields'] = fields.map((field) => {
					const value = {
						value: attachment.values ? attachment.values[field.slug] : null,
					};
					return { ...field, ...value };
				});
				attachment['file'] = JSON.parse(attachment['file']);
				delete attachment.values;
			});
		});

		Vue.set(state, 'attachments', attachments);

		// At this point the values can be extracted - the form elements will be bound to these values.
		const values = {};

		Object.keys(state.attachments).forEach((tabId) => {
			state.attachments[tabId].forEach((attachment) => {
				values[attachment.id] = {};
				attachment.fields.forEach((field) => (values[attachment.id][field.slug] = field.value));
			});
		});

		Vue.set(state.values, 'Attachments', values);
	},

	/**
	 * Store attachment.
	 */
	storeAttachment(state, { tabId, attachment }) {
		if (!state.attachments) {
			Vue.set(state, 'attachments', {});
		}

		if (!state.attachments[tabId]) {
			Vue.set(state.attachments, tabId, []);
		}

		state.attachments[tabId].push(attachment);
	},

	storeAttachments(state, { attachments }) {
		Vue.set(state, 'attachments', attachments);
	},

	/**
	 * Change image url of the attachment with another url or data url.
	 */
	updateAttachmentImage(state, { attachment, image }) {
		attachment.file.image = image;
	},

	/**
	 * Update multiple properties of the attachment using key-values.
	 */
	updateAttachment(state, { attachment, properties }) {
		if (attachment) {
			for (let key of Object.keys(properties)) {
				attachment[key] = properties[key];
			}
		}
	},

	/**
	 * Sort attachments in given tab by order.
	 */
	sortAttachmentsByOrder(state, { tabId }) {
		if (state.attachments[tabId]) {
			state.attachments[tabId] = state.attachments[tabId].sort((a, b) => a.order - b.order);
		}
	},

	/**
	 * If attachment changes its id, preserve old values using the new id.
	 */
	copyAttachmentValues(state, { oldId, newId }) {
		Vue.set(state.values['Attachments'], newId, state.values['Attachments'][oldId]);
		Vue.delete(state.values['Attachments'], oldId);
	},

	/**
	 * Delete attachment.
	 */
	deleteAttachment(state, { tabId, attachment }) {
		if (!attachment || !state.attachments[tabId]) {
			return;
		}

		const attachments = state.attachments[tabId].filter((a) => a.id !== attachment.id);

		Vue.set(state.attachments, tabId, attachments);
		Vue.delete(state.values['Attachments'], attachment.id);
		Vue.delete(state.conditionalVisibility['Attachments'], attachment.id);
	},

	/**
	 * Merge contributors with the state object.
	 */
	storeContributors(state, { contributors, contributorFields }) {
		Object.keys(contributors).forEach((tabId) => {
			// eslint-disable-next-line eqeqeq
			const fields = contributorFields.filter((field) => field.tabId == tabId);

			contributors[tabId].forEach((contributor) => {
				contributor['fields'] = fields.map((field) => {
					const value = { value: contributor.values[field.slug] || null };
					return { ...field, ...value };
				});
				delete contributor.values;
			});
		});

		Vue.set(state, 'contributors', contributors);

		// At this point the values can be extracted - the form elements will be bound to these values.
		const values = {};

		Object.keys(state.contributors).forEach((tabId) => {
			state.contributors[tabId].forEach((contributor) => {
				values[contributor.id] = {};
				contributor.fields.forEach((field) => (values[contributor.id][field.slug] = field.value));
			});
		});

		Vue.set(state.values, 'Contributors', values);
	},

	/**
	 * Store contributor.
	 */
	storeContributor(state, { tabId, contributor }) {
		if (!state.contributors[tabId]) {
			Vue.set(state.contributors, tabId, []);
		}

		state.contributors[tabId].push(contributor);
	},

	/**
	 * Delete contributor.
	 */
	deleteContributor(state, { tabId, contributorId }) {
		if (!state.contributors[tabId]) {
			return;
		}

		const contributors = state.contributors[tabId].filter((c) => c.id !== contributorId);

		Vue.set(state.contributors, tabId, contributors);
		Vue.delete(state.values['Contributors'], contributorId);
		Vue.delete(state.conditionalVisibility['Contributors'], contributorId);
	},

	storeTabContributors(state, { contributors, tabId }) {
		Vue.set(state.contributors, tabId, contributors || {});
	},

	/**
	 * Seed initial values when creating a new resource (contributor / attachment).
	 */
	seedValues(state, { resource, resourceId, fields }) {
		const values = {};
		fields.forEach((field) => (values[field.slug] = field.value));
		Vue.set(state.values[resource], resourceId, values);
	},

	/**
	 * Once the fields are available they have to be merged with the state object.
	 */
	storeCategories(state, categories) {
		if (state.chapterId && state.loadedChapters) {
			const index = state.loadedChapters.findIndex((c) => c.chapterId === state.chapterId);

			if (index >= 0) {
				Vue.set(state.loadedChapters, index, {
					chapterId: state.chapterId,
					categories: categories,
				});
			} else {
				state.loadedChapters.push({
					chapterId: state.chapterId,
					categories: categories,
				});
			}
		}
	},

	/**
	 * Store field value.
	 */
	storeFieldValue(state, { field, value, resourceId }) {
		if (resourceId) {
			if (!state.values[field.resource][resourceId]) {
				Vue.set(state.values[field.resource], resourceId, {});
			}

			Vue.set(state.values[field.resource][resourceId], field.slug, value);
		} else {
			Vue.set(state.values[field.resource], field.slug, value);
		}
	},

	/**
	 * Reset field values.
	 */
	resetFieldValues(state, field) {
		if (['Attachments', 'Contributors'].includes(field.resource)) {
			Object.keys(state.values[field.resource]).forEach((resourceId) => {
				if (Object.getOwnPropertyDescriptor(state.values[field.resource][resourceId], field.slug)) {
					Vue.set(state.values[field.resource][resourceId], field.slug, null);
				}
			});
		} else {
			if (Object.getOwnPropertyDescriptor(state.values[field.resource], field.slug)) {
				Vue.set(state.values[field.resource], field.slug, null);
			}
		}
	},

	/**
	 * Store conditional visibility.
	 */
	storeConditionalVisibility(state, { conditionalVisibility, resource, resourceId }) {
		if (resourceId) {
			Vue.set(state.conditionalVisibility[resource], resourceId, conditionalVisibility);
		} else {
			Vue.set(state.conditionalVisibility, resource, conditionalVisibility);
		}
	},

	/**
	 * Store status
	 */
	storeStatus(state, status) {
		Vue.set(state, 'status', status);
	},

	/*
	 * Store chapters.
	 */
	storeChapters(state, chapters) {
		Vue.set(state, 'chapters', chapters);

		if (chapters.length === 1) {
			Vue.set(state, 'chapterId', chapters[0].id);
			return;
		}

		if (state.entry) {
			const chapter = state.chapters.find((chapter) => chapter.id === state.entry.chapterId);

			const locked = state.locks.lockedCategory || state.locks.readOnly;

			if (!chapter || (chapter.deletedAt && !locked)) {
				return;
			}

			if (locked || chapter.active || state.hasManagerRole) {
				Vue.set(state, 'chapterId', chapter.id);
			}
		}
	},

	/**
	 * Update entry title.
	 */
	updateTitle(state, title) {
		Vue.set(state, 'title', title);
	},

	/**
	 * Update chapterId.
	 */
	updateChapterId(state, id) {
		Vue.set(state, 'chapterId', id);
		Vue.set(state, 'selectedCategoryId', null);
	},

	/**
	 * Set chapterId.
	 */
	setChapterId(state, id) {
		Vue.set(state, 'chapterId', id);
	},

	/**
	 * Store loading status
	 */
	setTabsLoadingStatus: (state, status) => Vue.set(state, 'tabsLoaded', status),
	setChaptersLoadingStatus: (state, status) => Vue.set(state, 'chaptersLoaded', status),
	setAttachmentsLoadingStatus: (state, status) => Vue.set(state, 'attachmentsLoaded', status),
	setContributorsLoadingStatus: (state, status) => Vue.set(state, 'contributorsLoaded', status),
	setRefereesLoadingStatus: (state, status) => Vue.set(state, 'refereesLoaded', status),
	setLoadedTabFields: (state, tabFields) => Vue.set(state, 'loadedTabFields', tabFields),
	setTabFieldsLoading: (state, { tabId, status }) =>
		Vue.set(state, 'tabFieldsLoading', {
			...state.tabFieldsLoading,
			...{ [tabId]: status },
		}),

	/**
	 * Store the uploader status
	 */
	setUploaderStatus(state, { uploaderId, isUploading }) {
		Vue.set(state.uploaderStatus, uploaderId, isUploading);

		// Check whether at least one uploader is uploading a file
		const uploadInProgress = Object.values(state.uploaderStatus).some((status) => status === true);
		Vue.set(state, 'uploadInProgress', uploadInProgress);
	},

	/**
	 * Reset state to default state
	 */
	resetState(state) {
		Object.assign(state, defaultState());
	},

	/**
	 * update contributors with newly saved records
	 *
	 * @param savedContributorIds - an array of ids for contributors that have been saved
	 */
	updateContributors(state, savedContributorIds) {
		for (let tabId in savedContributorIds) {
			state.contributors[tabId]
				.filter((contributor) => `${contributor.id}`.match(/^new-.*$/))
				.forEach((c, i) => {
					state.values['Contributors'][savedContributorIds[tabId][i]] = state.values['Contributors'][c.id];
					delete state.values['Contributors'][c.id];
					c.id = savedContributorIds[tabId][i];
				});
		}
	},

	/**
	 * Move field to a tab
	 */
	moveFieldToTab(state, { tabSlug, fieldSlug }) {
		const tab = state.tabs.find((tab) => tab.slug === tabSlug);
		const field = state.fields.find((field) => field.slug === fieldSlug);

		if (tab && field) {
			Vue.set(state, 'fields', [
				...state.fields.map((field) => (field.slug !== fieldSlug ? field : { ...field, tabId: tab.id })),
			]);
		}
	},

	/**
	 * Add field
	 */
	addField(state, field) {
		if (state.fields.find((f) => f.id === field.id)) {
			return;
		}

		state.fields.push(field);

		Vue.set(
			state,
			'fields',
			state.fields.sort((a, b) => parseFloat(a.order) - parseFloat(b.order))
		);
		this.dispatch('entryForm/setFieldTitlesForFormula');
	},

	/**
	 * Update field
	 */
	updateField(state, field) {
		const index = state.fields.findIndex((f) => f.id === field.id);

		if (index >= 0) {
			Vue.set(state.fields, index, Object.assign({}, field));
			this.dispatch('entryForm/setFieldTitlesForFormula');
		}
	},

	/**
	 * Replace field
	 */
	replaceField(state, { oldField, newField }) {
		let index = state.fields.findIndex((f) => f.id === oldField.id);

		if (index >= 0) {
			Vue.set(state.fields, index, newField);
			this.dispatch('entryForm/setFieldTitlesForFormula');
		}

		const conditionalVisiblity = state.conditionalVisibility[oldField.resource][oldField.slug];

		if (conditionalVisiblity) {
			Vue.set(state.conditionalVisibility[newField.resource], newField.slug, conditionalVisiblity);
			Vue.delete(state.conditionalVisibility[oldField.resource], oldField.slug);
		}
	},

	/**
	 * Remove field
	 */
	removeField(state, slug) {
		Vue.set(
			state,
			'fields',
			state.fields.filter((f) => f.slug !== slug)
		);
		this.dispatch('entryForm/setFieldTitlesForFormula');
	},

	/**
	 * Add tab
	 */
	addTab(state, tab) {
		if (state.tabs.find((t) => t.id === tab.id)) {
			return;
		}

		state.tabs.push(tab);

		Vue.set(
			state,
			'tabs',
			state.tabs.sort((a, b) => parseFloat(a.order) - parseFloat(b.order))
		);
	},

	/**
	 * Update tab
	 */
	updateTab(state, tab) {
		const index = state.tabs.findIndex((t) => t.id === tab.id);

		if (index >= 0) {
			Vue.set(state.tabs, index, Object.assign({}, tab));
		}
	},

	/**
	 * Update tabs
	 */
	updateTabs(state, tabs) {
		tabs.forEach(function (tab) {
			const index = state.tabs.findIndex((t) => t.id === tab.id);

			if (index >= 0) {
				Vue.set(state.tabs, index, Object.assign({}, tab));
			}
		});
	},

	/**
	 * Replace tab
	 */
	replaceTab(state, { oldTab, newTab }) {
		let index = state.tabs.findIndex((t) => t.id === oldTab.id);

		if (index >= 0) {
			Vue.set(state.tabs, index, newTab);
		}
	},

	/**
	 * Remove tab
	 */
	removeTab(state, slug) {
		Vue.set(
			state,
			'tabs',
			state.tabs.filter((t) => t.slug !== slug)
		);
	},

	/**
	 * Add category
	 */
	addCategory(state, category) {
		let index = state.loadedChapters.findIndex((c) => c.chapterId === state.chapterId);

		if (index >= 0) {
			state.loadedChapters[index].categories.push(category);
		}
	},

	/**
	 * Update category
	 */
	updateCategory(state, category) {
		state.loadedChapters.forEach((chapter, i) => {
			const index = chapter.categories.findIndex((c) => c.id === category.id);

			if (index >= 0) {
				Vue.set(state.loadedChapters[i].categories, index, Object.assign({}, category));
			}
		});
	},

	addFileTokens(state, { fileTokens, category }) {
		state.loadedChapters.forEach((chapter, i) => {
			const index = chapter.categories.findIndex((c) => c.id === category.id);

			if (index >= 0) {
				Vue.set(state.loadedChapters[i].categories, index, {
					...state.loadedChapters[i].categories[index],
					fileTokens: fileTokens,
				});
			}
		});
	},

	/**
	 * Remove category
	 */
	removeCategory(state, slug) {
		state.loadedChapters.forEach((chapter, i) => {
			Vue.set(
				state.loadedChapters[i],
				'categories',
				state.loadedChapters[i].categories.filter((c) => c.slug !== slug)
			);
		});
	},

	/**
	 * Replace category's files
	 */
	replaceCategoryFiles(state, { oldCategory, newCategory }) {
		state.loadedChapters.forEach((chapter, i) => {
			const j = state.loadedChapters[i].categories.findIndex((category) => category.id === oldCategory.id);

			if (j >= 0) {
				Vue.set(state.loadedChapters[i].categories, j, {
					...state.loadedChapters[i].categories[j],
					files: newCategory.files,
				});
			}
		});
	},

	/**
	 * Add chapter
	 */
	addChapter(state, chapter) {
		if (state.chapters.find((c) => c.id === chapter.id)) {
			return;
		}

		state.chapters.push(chapter);
	},

	/**
	 * Update chapter
	 */
	updateChapter(state, chapter) {
		const index = state.chapters.findIndex((c) => c.id === chapter.id);

		if (index >= 0) {
			Vue.set(state.chapters, index, Object.assign({}, chapter));
		}
	},

	/**
	 * Replace chapter
	 */
	replaceChapter(state, { oldChapter, newChapter }) {
		let index = state.chapters.findIndex((c) => c.id === oldChapter.id);

		if (index >= 0) {
			Vue.set(state.chapters, index, newChapter);
		}
	},

	/**
	 * Remove chapter
	 */
	removeChapter(state, slug) {
		Vue.set(
			state,
			'chapters',
			state.chapters.filter((c) => c.slug !== slug)
		);
	},

	/**
	 * Replace chapter's files
	 */
	replaceChapterFiles(state, { oldChapter, newChapter }) {
		let index = state.chapters.findIndex((c) => c.id === oldChapter.id);

		if (index >= 0) {
			Vue.set(state.chapters[index], 'files', newChapter.files);
		}
	},

	updateFormChapters(state, chapters) {
		const updatedChapters = state.chapters.map((chapter) => {
			chapter.inForm = chapters.includes(chapter.id);
			return chapter;
		});

		Vue.set(state, 'chapters', updatedChapters);
	},

	/**
	 * Set form
	 */
	setForm(state, form) {
		state.form = form;
	},

	/**
	 * Set form type
	 */
	setFormType(state, type) {
		Vue.set(state.form, 'type', type);
	},

	/**
	 * Finish recalculation
	 */
	finishRecalculation(state, fieldSlug) {
		const index = state.fields.findIndex((f) => f.slug === fieldSlug);

		if (index >= 0 && state.fields[index].isRecalculating) {
			Vue.set(state.fields, index, {
				...state.fields[index],
				isRecalculating: false,
			});
		}
	},

	storeLinks(state, { links }) {
		Vue.set(state, 'links', links);
	},

	addLink(state, { tabId, linkId }) {
		if (!state.links[tabId]) {
			Vue.set(state.links, tabId, []);
		}

		state.links[tabId].push({
			id: linkId || makeId(),
			tabId: tabId,
			url: '',
			extra: '',
		});
	},

	deleteLink(state, link) {
		Vue.set(
			state.links,
			link.tabId,
			(state.links[link.tabId] || []).filter((l) => l.id !== link.id)
		);
		Vue.set(state, 'formEdited', true);
	},

	updateLink(state, link) {
		Vue.set(
			state.links,
			link.tabId,
			(state.links[link.tabId] || []).map((l) => (l.id === link.id ? link : l))
		);
		Vue.set(state, 'formEdited', true);
	},

	storeFieldErrors(state, { key, errors, tab }) {
		let tabFieldsErrors = state.tabFieldsErrors[tab] ? Object.assign({}, state.tabFieldsErrors[tab]) : {};
		if (errors && errors.length) {
			Vue.set(state.errors, key, errors);
			tabFieldsErrors[key] = true;
		} else {
			Vue.delete(state.errors, key);
			delete tabFieldsErrors[key];
		}

		Vue.set(state.tabFieldsErrors, tab, tabFieldsErrors);
	},

	storeApiErrors(state, errors) {
		let apiErrors = [];
		for (let i in errors) {
			apiErrors = [...apiErrors, ...tabsFinder(errors[i].type)(errors[i], state)];
		}

		Vue.set(
			state,
			'tabApiErrors',
			apiErrors.filter((v, i) => apiErrors.indexOf(v) === i)
		);
	},

	addTabIdToApiErrors(state, tabId) {
		Vue.set(state, 'tabApiErrors', [...new Set([...state.tabApiErrors, tabId])]);
	},

	deleteTabIdApiErrors(state, tabId) {
		Vue.set(state, 'tabApiErrors', [...new Set([...state.tabApiErrors.filter((error) => error !== tabId)])]);
	},

	flushLoadedChapters(state) {
		Vue.set(
			state,
			'loadedChapters',
			state.loadedChapters.filter((c) => c.chapterId === state.chapterId)
		);
	},

	updateCategories(state, categories) {
		Vue.set(state, 'categories', categories);
	},

	clearFormCoverImage(state) {
		Vue.set(state.form, 'coverImage', null);
	},

	setFormEdited(state, value) {
		Vue.set(state, 'formEdited', value);
	},

	setEligibilityContentBlock(state, contentBlock) {
		Vue.set(state, 'eligibilityContentBlock', contentBlock);
	},

	setEntryEligibility(state, isEligible) {
		Vue.set(state, 'entry', { ...state.entry, isEligible: isEligible });
	},

	storeReferee(state, { tabId, referee }) {
		if (!state.referees[tabId]) {
			Vue.set(state.referees, tabId, []);
		}

		state.referees[tabId].push(referee);
	},
	storeReferees(state, { referees, refereeFields }) {
		Object.keys(referees).forEach((tabId) => {
			const fields = refereeFields.filter((field) => String(field.tabId) === String(tabId));

			referees[tabId].forEach((referee) => {
				referee['fields'] = fields.map((field) => {
					const value = { value: referee.values[field.slug] || null };
					return { ...field, ...value };
				});
				delete referee.values;
			});
		});

		Vue.set(state, 'referees', referees);

		const values = {};
		Object.keys(state.referees).forEach((tabId) => {
			state.referees[tabId].forEach((referee) => {
				values[referee.id] = {};
				referee.fields.forEach((field) => (values[referee.id][field.slug] = field.value));
			});
		});

		Vue.set(state.values, 'Referees', values);
	},

	updateRefereeField(state, { referee, field, value }) {
		const index = state.referees[referee.tabId].findIndex((r) => r.id === referee.id);

		Vue.set(state.referees[referee.tabId][index], field, value);
		Vue.set(state, 'formEdited', true);
	},

	updateReferee(state, referee) {
		const index = state.referees[referee.tabId].findIndex((r) => r.id === referee.id);

		Vue.set(state.referees[referee.tabId], index, referee);
		Vue.set(state, 'formEdited', true);
	},
	updateRefereesByTab(state, { tabId, referees }) {
		const nonObserverReferees = Object.assign({}, referees);
		Vue.set(state.referees, tabId, referees);

		if (Array.isArray(nonObserverReferees)) {
			nonObserverReferees.forEach(function (referee) {
				const refereeValues = Object.fromEntries(
					// eslint-disable-next-line no-unused-vars
					Object.entries(referee.fields).map(([key, field]) => [field.slug, field.value])
				);

				Vue.set(state.values['Referees'], referee.id, refereeValues);
			});
		}
	},
	deleteReferee(state, { tabId, refereeId }) {
		if (!state.referees[tabId]) {
			return;
		}

		const referees = state.referees[tabId].filter((r) => r.id !== refereeId);

		Vue.set(state.referees, tabId, referees);
		Vue.delete(state.values['Referees'], refereeId);
		Vue.delete(state.conditionalVisibility['Referees'], refereeId);
		Vue.set(state, 'formEdited', true);
	},

	updateReferees(state, savedReferees) {
		if (Array.isArray(savedReferees)) {
			savedReferees.forEach(function (referee, indexReferee) {
				let tabId = savedReferees[indexReferee].tabId;
				let refereeId = savedReferees[indexReferee].id;

				if (!state.values['Referees'][refereeId]) {
					state.referees[tabId].push({ ...savedReferees[indexReferee], requestCompleted: false, requestSent: false });
					state.values['Referees'][refereeId] = savedReferees[indexReferee].values || [];
				}

				state.referees[tabId]
					.filter((referee) => `${referee.id}`.match(/^new-.*$/))
					.forEach((r) => {
						const referees = state.referees[tabId].filter((referee) => referee.id !== r.id);
						Vue.set(state.referees, r.tabId, referees);
						Vue.delete(state.values['Referees'], r.id);
						Vue.delete(state.conditionalVisibility['Referees'], r.id);
					});
			});
		}
	},

	setCollaboratorAccess(state, collaboratorAccess) {
		Vue.set(state, 'collaboratorAccess', collaboratorAccess);
	},
};

<template>
	<div style="margin-right: 2px">
		<div class="row prl">
			<div class="col-xs-6 col-xs-offset-1 plrs">
				<label>{{ lang.get('fields.form.label.label') }}</label>
			</div>
			<div class="col-xs-4 plrs">
				<label>
					<i class="af-icons af-icons-lock align-middle"></i>
					{{ lang.get('fields.form.options.label') }}

					<help-icon align="middle" :content="lang.get('fields.form.options.help')"></help-icon>
				</label>
			</div>
		</div>
		<div ref="draggableContainer" class="row draggable-options-container" :style="minHeightCss">
			<div ref="draggable">
				<draggable
					:list="options"
					draggable=".draggable-option"
					class="draggable-columns"
					animation="150"
					filter=".form-control"
					:prevent-on-filter="false"
				>
					<draggable-item
						v-for="(option, index) in options"
						:id="'testOption' + index"
						:key="'draggable-option-' + index"
						:class="[{ 'draggable-option': !readOnly, dragging: grabbedItem === option.key }]"
						:aria-labelledby="'optionLabel-' + index"
						role="option"
						:index="index"
						:item="option"
						:items="options"
						item-key="key"
						:item-description="option['translated'][lang.locale] || option['translated'][lang.fallback]"
						:grabbed-item="grabbedItem"
						@grabItem="(key) => (grabbedItem = key)"
						@reorderItems="(reorderedItems) => (options = reorderedItems)"
					>
						<div>
							<div class="row prl">
								<div v-if="!readOnly" class="col-xs-1 text-center plrs prn mtm" style="cursor: all-scroll">
									<span class="af-icons af-icons-up-down handle-af dark-icon"></span>
								</div>
								<div v-else class="col-xs-1 text-center plrs prn mtm" disabled="disabled">
									<span class="af-icons af-icons-up-down handle-af" disabled="disabled"></span>
								</div>
								<div class="col-xs-6 plrs">
									<multilingual-option
										ref="multilingual"
										:index="index"
										:supported-languages="supportedLanguages"
										:resource="option.translated"
										property="optionText"
										:focused-item="selectedLabelFocus"
										:focused-lang="focusLang"
										:disabled="readOnly"
										@input="handleLabelInput"
										@change="handleChange"
										@changeFocus="changeFocus"
										@error="handleLabelError"
									/>
								</div>
								<div :class="['col-xs-4', 'plrs']">
									<div class="form-group">
										<div :class="{ error: option.error }">
											<input
												type="text"
												class="form-control"
												:aria-label="`internal-value-${index}`"
												:value="option.key"
												:disabled="!isEnabled(option) || readOnly"
												:aria-invalid="!!option.error"
												required="required"
												@focusin="focusOption(index, true)"
												@focusout="focusOption(index, false)"
												@input="(e) => updateKey(index, e.target.value)"
											/>
											<div
												v-if="option.error && selectedOptionFocus === index"
												class="alert-error sticky inline key-error text-left"
												role="alert"
												style="margin-left: -5px !important"
											>
												{{ option.error }}
											</div>
										</div>
										<scoring-input
											v-if="field.autoScoring"
											:id="`scores-${index}`"
											v-model.number="option.score"
											:name="`scores[${index}]`"
											:label="lang.get('fields.auto_score')"
										/>
									</div>
								</div>

								<div v-if="!readOnly" class="col-xs-1 text-center plrs pln mtm">
									<a
										href="javascript:void(0)"
										@click.prevent="option.new ? remove(index) : confirmDelete(index)"
										@keydown.enter.prevent="option.new ? remove(index) : confirmDelete(index)"
										@keydown.space.prevent="option.new ? remove(index) : confirmDelete(index)"
									>
										<span class="af-icons af-icons-close-circle remove not-draggable dark-icon"></span>
										<span class="sr-only">{{ lang.get('buttons.delete') }}</span>
									</a>
								</div>
							</div>
						</div>
						<div class="row">
							<div :class="['col-xs-12', 'plrm', { marginTop5: multiLanguage }]">
								<inserter v-if="!readOnly" :active="active" @clicked="addOption(index)"> </inserter>
							</div>
						</div>
					</draggable-item>
				</draggable>
			</div>
		</div>
		<input id="internal-value-options" :value="fieldValue" name="options" type="hidden" />
		<template v-if="labelValues">
			<div v-for="language in supportedLanguages" :key="language">
				<input :name="getOptionTextName(language)" type="hidden" :value="JSON.stringify(labelValues[language])" />
			</div>
		</template>

		<confirmation-modal
			modal-id="modal-target-delete-option"
			:show-modal="confirmDeletion > -1"
			:confirmation="lang.get('fields.alerts.delete-option')"
			:confirm-button-label="lang.get('buttons.delete')"
			:cancel-button-label="lang.get('buttons.cancel')"
			:close-on-confirm="true"
			@confirmed="remove(confirmDeletion)"
			@closed="confirmDeletion = -1"
		/>

		<loader v-if="!isWrapped" :id="loaderId" />
	</div>
</template>

<script>
import $ from 'jquery';
import Draggable from 'vuedraggable';
import Inserter from '@/lib/components/Shared/Inserter';
import MultilingualOption from '@/lib/components/Translations/MultilingualOption';
import ConfirmationModal from '@/lib/components/Shared/ConfirmationModal';
import HelpIcon from '@/lib/components/Shared/HelpIcon';
import Loader from '@/lib/components/Loader';
import ScoringInput from '@/lib/components/Fields/ScoringInput';
import { mapOptionsOrdered } from '@/lib/options';
import DraggableItem from '@/lib/components/Shared/DraggableItem.vue';

export default {
	inject: ['lang'],
	components: {
		Loader,
		HelpIcon,
		Draggable,
		Inserter,
		MultilingualOption,
		ConfirmationModal,
		ScoringInput,
		DraggableItem,
	},
	props: {
		field: {
			type: Object,
			required: true,
		},
		supportedLanguages: {
			type: Array,
			required: true,
		},
		readOnly: {
			type: Boolean,
			default: false,
		},
		isWrapped: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			options: [],
			errors: {},
			labelErrors: {},
			confirmDeletion: -1,
			insertPosition: null,
			initialInsertPosition: 'initial',
			selectedLabelFocus: -1,
			selectedOptionFocus: -1,
			minHeight: 60,
			focusLang: this.lang.fallback,
			grabbedItem: null,
		};
	},
	computed: {
		labelValues() {
			return this.options.reduce((labelValues, o) => {
				this.supportedLanguages.forEach((lang) => {
					labelValues[lang] = { ...(labelValues[lang] || {}), [o.key]: o.translated[lang] ?? null };
				});
				return labelValues;
			}, {});
		},
		fieldValue() {
			return mapOptionsOrdered(this.options, 'key', 'score', 0);
		},
		active() {
			return this.position === this.insertPosition;
		},
		minHeightCss() {
			return this.minHeight <= 800 ? `min-height:${this.minHeight}px` : '';
		},
		multiLanguage() {
			return this.supportedLanguages.length > 1;
		},
		loaderId() {
			return !this.isWrapped ? 'draggable-options-loader' : 'loader';
		},
	},
	watch: {
		minHeight() {
			if (this.minHeight === 800) {
				this.$refs.draggableContainer.classList.add('scroll-box-shadow');
			} else {
				this.$refs.draggableContainer.classList.remove('scroll-box-shadow');
			}
		},
		errors() {
			this.emitErrors();
		},
		labelErrors() {
			this.emitErrors();
		},
		options(currentOptions) {
			if (currentOptions.length === 0) {
				this.insert(-1);
				this.handleLabelValueChange();
			}
		},
		fieldValue() {
			this.handleLabelValueChange();
		},
		labelValues() {
			this.handleLabelValueChange();
		},
	},
	beforeDestroy: function () {
		this.$emit('errors', false);
	},
	mounted() {
		if (!this.field.options) {
			this.options = [];
		} else {
			let fieldOptions = this.field.options;
			if (typeof fieldOptions === 'string') {
				fieldOptions = fieldOptions.split('\r\n').map((option) => ({ id: option }));
			}

			const translated = JSON.parse(JSON.stringify(this.field.translated));
			this.options = fieldOptions.map((option) => ({
				key: option.id,
				new: option.id === '',
				score: option.score || 0,
				translated: this.supportedLanguages.reduce(
					(trans, lang) => ({
						...trans,
						[lang]: (translated[lang] || {}).optionText !== undefined ? translated[lang].optionText[option.id] : '\r\n',
					}),
					{}
				),
			}));
			if (this.options.length === 1) {
				this.validateOption(0, this.options[0].key);
			}
		}

		this.hideLoader();
	},
	updated() {
		this.$nextTick(() => {
			const min = (this.$refs.draggable.clientHeight > 0 ? this.$refs.draggable.clientHeight : this.minHeight) + 5;
			this.minHeight = min < 800 ? min : 800;
		});
	},
	methods: {
		addOption(currentIndex) {
			this.insert(currentIndex);
			this.handleLabelValueChange();
		},
		setInsertPosition(key) {
			this.insertPosition = key;
		},
		resetInsertPosition() {
			this.insertPosition = null;
		},
		toggleSelector(key) {
			if (this.insertPosition === key) {
				this.resetInsertPosition();
				return;
			}

			this.setInsertPosition(key);
		},
		getIndex(options, key) {
			return options.map((option) => option.key).indexOf(key);
		},
		confirmDelete(index) {
			this.confirmDeletion = index;
		},
		remove(index) {
			if (index === undefined) {
				index = this.confirmDeletion;
			}

			if (index >= 0) {
				this.options.splice(index, 1);

				this.confirmDeletion = -1;
				if (this.errors[index]) {
					this.setError(index, null);
				}

				this.handleLabelValueChange();
			}
		},
		optionIsEmpty(index) {
			const option = this.options[index];
			return (
				option === undefined ||
				(option.key.length === 0 && !this.supportedLanguages.some((sl) => option.translated[sl].length > 0))
			);
		},
		async handleLabelInput(value, language, index) {
			if (Array.isArray(value)) {
				value = value.filter((v) => v !== '');

				if (value.length > 100) {
					await this.showLoader();
					await this.parseLabelInput(value, language, index);
					this.hideLoader();
				} else {
					await this.parseLabelInput(value, language, index);
				}
			}

			if (language === this.lang.fallback) {
				if (
					this.canUpdateKey(index, value) &&
					(Math.abs(this.generateKey(value).length - this.options[index].key.length) === 1 || this.options[index].key === '')
				) {
					this.updateKey(index, this.generateKey(value));
				}
			}

			if (!Array.isArray(value)) {
				this.handleLabelValueChange();
			}
		},
		async parseLabelInput(value, language, index) {
			let added = 0;
			let position = index + 1;
			const shift = this.optionIsEmpty(index);

			for (const part of value) {
				const i = value.indexOf(part);
				const label = part.trim();
				position = index - 1 + i;
				if (label !== '') {
					const key = language === this.lang.fallback ? this.generateKey(label) : '';
					added += this.insert(position, key, label, language, shift);
				}
			}

			if (added === 0) {
				if (position <= index) {
					position += index - position + 1;
				}

				if (this.options[position] !== undefined) {
					this.changeFocus(position, language);
				} else {
					this.insert(index, undefined, undefined, language);
					this.changeFocus(index + 1, language);
				}
			} else {
				this.changeFocus(position + 1, language);
			}

			this.handleLabelValueChange();
		},
		insert(index, key, value, language, shift = false) {
			const insertAtIndex = index + 1;
			let added = 0;
			let translations = {};
			let option = {};

			this.supportedLanguages.forEach((lang) => {
				translations[lang] = language === lang && value ? value : '';
			});

			if (shift) {
				const deleteCount = this.optionIsEmpty(insertAtIndex) ? 1 : 0;
				this.options.splice(insertAtIndex, deleteCount, {
					key: '',
					translated: translations,
					new: true,
					score: 0,
				});
			}

			if (this.options[insertAtIndex] !== undefined && value !== undefined) {
				option = this.options[insertAtIndex];

				if (language !== undefined) {
					option.translated[language] = translations[language];
				} else {
					option.translated = translations;
				}

				if (language === this.lang.fallback && this.canUpdateKey(insertAtIndex, value)) {
					option.key = key;
				}

				this.options[insertAtIndex] = option;
			} else {
				option = {
					key: key || '',
					translated: translations,
					new: true,
					score: 0,
				};

				this.options.splice(insertAtIndex, 0, option);
				added++;
			}

			this.resetInsertPosition();

			this.validateOption(insertAtIndex, option.key);

			return added;
		},
		getOptionTextName(language) {
			return `translated[optionText][${language}]`;
		},
		updateKey(index, value) {
			this.options[index].key = value;
			this.validateOption(index, value);
		},
		canUpdateKey(index, value) {
			return (
				!Array.isArray(value) &&
				this.options[index].new &&
				(this.options[index].key === '' ||
					this.generateKey(value).indexOf(this.options[index].key) === 0 ||
					this.options[index].key.indexOf(this.generateKey(value)) === 0)
			);
		},
		handleChange(value, language, index) {
			value = value.trim();
			this.options[index].translated[language] = value;
		},
		validateOption(index, value) {
			if (value === '') {
				this.options[index].error = this.lang.get('validation.required_generic');
				this.setError(index, true);
				return;
			} else {
				this.options[index].error = null;
				this.setError(index, null);
				this.handleLabelError(index, false);
			}

			const keys = this.options.map((option) => option.key);
			const firstIndex = keys.indexOf(value);
			const lastIndex = keys.lastIndexOf(value);
			const isDuplicate = firstIndex !== -1 && firstIndex !== lastIndex;

			if (isDuplicate) {
				this.options[index].error = this.lang.get('fields.form.options.validation.unique');
				this.setError(index, true);
				return;
			}

			if (this.errors[index] !== undefined) {
				this.options[index].error = null;
				this.setError(index, null);
				this.handleLabelError(index, false);
			}
		},
		generateKey(value) {
			return value.toString().replace(/["']/g, '');
		},

		handleLabelValueChange() {
			const options = this.options.map((option) => ({
				id: option.key.trim(),
				score: option.score || 0,
				text: option.translated[this.getLocale()] || option.translated[this.lang.fallback] || '',
			}));

			let translations = JSON.parse(JSON.stringify(this.field.translated));

			this.supportedLanguages.forEach((lang) => {
				if (translations[lang] === undefined) {
					translations[lang] = {};
				}

				translations[lang]['optionText'] = this.labelValues[lang];
			});

			if (this.shouldUpdate(this.field.translated, translations) || this.shouldUpdate(options, this.field.options)) {
				this.$emit('input', {
					options: options,
					translated: translations,
				});
				this.$emit('updated');
			}
		},
		shouldUpdate(compare1, compare2) {
			return JSON.stringify(compare1) !== JSON.stringify(compare2);
		},
		setError(index, value) {
			this.errors = {
				...this.errors,
				[index]: value,
			};
		},
		isEnabled(option) {
			return option.new;
		},
		changeFocus(index, lang) {
			this.selectedLabelFocus = index;
			this.focusLang = lang;
		},
		handleLabelError(index, value) {
			this.labelErrors = {
				...this.labelErrors,
				[index]: value,
			};
		},
		emitErrors() {
			this.$emit(
				'errors',
				Object.values(this.errors).some((error) => error) || Object.values(this.labelErrors).some((error) => error)
			);
		},
		focusOption(index, value) {
			this.selectedLabelFocus = -1;
			this.selectedOptionFocus = value ? index : -1;
		},
		getLocale() {
			return this.supportedLanguages.some((language) => language === this.lang.locale)
				? this.lang.locale
				: this.lang.fallback;
		},
		async showLoader() {
			return new Promise((resolve) => {
				$('#' + this.loaderId)
					.show()
					.fadeTo(250, 0.8, () => resolve(true));
			});
		},
		hideLoader() {
			$('#' + this.loaderId).fadeTo(250, 0, function () {
				$(this).hide();
			});
		},
	},
};
</script>
<style scoped>
.key-error {
	margin: 0 !important;
	padding-top: 0 !important;
	padding-bottom: 0 !important;
}

.draggable-options-container {
	max-height: 700px;
	overflow-y: scroll;
	overflow-x: hidden;
}

.draggable-option {
	margin: 1px -5px 0 -3px;
	padding: 6px 0 0 7px;
}

.dark-icon {
	color: #000;
	font-size: 18px;
}

.row {
	padding: 0 5px;
}
.row.prl {
	padding-right: 15px;
}
.marginTop5 {
	margin-top: -5px;
}

.scroll-box-shadow {
	padding-top: 8px;
	box-shadow: inset 0px 11px 8px -9px #ccc, inset 0px -11px 8px -9px #ccc;
}
</style>
